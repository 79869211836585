const locale = {
  errors: {
    ER_DUP_ENTRY: 'Já existe um cadastro com os dados informados.',
    400: 'Um erro inesperado aconteceu, tente novamente\nmais tarde ou entre em contato com o\nadministrador do sistema.',
    500: 'Um erro inesperado aconteceu, tente novamente\nmais tarde ou entre em contato com o\nadministrador do sistema.',
    401: 'Usuário ou senha inválidos',
    ER_DUP_ENTRY_EMAIL: 'Já existe um cadastro com o e-mail informado.',
    ER_DUP_ENTRY_PHONE: 'Já existe um cadastro com o celular informado.',
    ER_DUP_INVITATION:
      'Você está tentando convidar um usuário que já existe, mas com uma função diferente.',
  },
  success: {
    200: 'Dados enviados com sucesso',
    201: 'Dados enviados com sucesso',
  },
  login: {
    title: 'Login',
    forgotPassword: 'Esqueci minha senha',
    notRegistered: 'Não tem cadastro?',
    register: 'Fazer cadastro',
    form: {
      login: 'Login',
      loginPlaceholder: 'Insira seu e-mail',
      password: 'Senha',
      passwordPlaceholder: 'Insira sua senha',
      signIn: 'Entrar',
    },
  },
  register: {
    title: 'Cadastro',
    step1: '1/2',
    step2: '2/2',
    form: {
      name: 'Nome',
      namePlaceholder: 'Insira seu nome',
      email: 'Email',
      emailPlaceholder: 'Insira seu e-mail',
      password: 'Senha',
      passwordPlaceholder: 'Insira sua senha',
      passwordConfirm: 'Confirmar senha',
      passwordConfirmPlaceholder: 'Confirme sua senha',
      next: 'Próximo',
      phone: 'Celular',
      phonePlaceholder: '(xx) xxxxx-xxxx',
      code: 'Código',
      codePlaceholder: 'Insira o código de verificação',
      sendCode: 'Enviar código',
      forward: 'Avançar',
    },
    confirmationCodeInstruction: 'Clique e receba um código via sms.',
  },
  exportPdf: 'Exportar PDF',
  exportCsv: 'Exportar CSV',
  imagesAlt: {
    access: 'imagem de acesso menupass',
    profileHeader: 'usuário logado',
    miniLogo: 'Menu Pass',
    add: 'adicionar nova entidade',
    mewEntity: 'Novo Cadastro',
    new: 'Novo Cadastro',
    paymentData: 'Payment Data',
    left: 'left',
    right: 'right',
    cards: 'Cartoes',
    profile: 'Profile',
    product: 'Produtos',
    edit: 'Editar',
  },
  header: {
    entities: 'Entidades',
    payments: 'Pagamentos',
    plans: 'Planos',
    editProfile: 'Editar Perfil',
    logout: 'Sair do Sistema',
  },
  subheader: {
    administration: 'Administradores',
    sectors: 'Setores',
    responsible: 'Responsáveis',
    users: 'Usuários',
    products: 'Produtos',
    categories: 'Categorias',
    highlight: 'Destaques',
    system: 'Sistema',
    finance: 'Financeiro',
    invitation: 'Convites',
    totem: 'Totens',
  },
  home: {
    title: 'Entidades',
  },
  newEntity: {
    title: 'Nova Entidade',
    new: 'Novo Cadastro',
    content:
      'Crie uma nova entidade a ser gerenciada. A entidade\né a organização (escola, universidade, empresa) onde\na sua cantina está localizada. Aqui você pode escolher\no plano MenuPass além cadastrar o meio de pagamento\ndesejado.',
    entities: 'Entidades',
    plans: 'Planos',
    basic: 'Informações Básicas',
    subAccount: 'Informações Subconta',
    bank: 'Informações Bancárias',
    logo: 'Logo',
    next: 'Próximo',
    cancel: 'Cancelar',
    planContent: 'Selecione um plano e um meio de pagamento:',
    basicContent: 'Insira as informações abaixo para criar uma nova entidade',
    subAccountContent: 'Insira as informações abaixo para criar uma subconta',
    bankContent: 'Insira as informações abaixo para criar uma conta',
    logoContent: 'Faça o upload de uma imagem de no mínimo\n200 x 200 px.',
    plan: 'Plano',
    card: 'Cartão',
    save: 'Salvar',
    back: 'Voltar',
    addPayment: 'Novo cartão',
    freeTrial: 'Teste gratuito',
    cardPlaceholder: 'Selecione um cartão',
    freeTrialTime: 'Grátis por 15 dias',
    update: 'Atualizar',
    edit: 'Editar Cadastro',
    cancelPlan: 'Cancelar Contratação',
    form: {
      content:
        'Preencha as informações abaixo para registrar informações para emissão de nota fiscal',
      name: 'Nome da entidade',
      cnpj: 'CPF/CNPJ',
      cep: 'CEP',
      state: 'Estado',
      city: 'Cidade',
      street: 'Rua',
      number: 'Número',
      complement: 'Complemento',
      phone: 'Telefone',
    },
    formEntityInfoLegal: {
      name: 'Nome da empresa',
      commercialName: 'Nome da fantasia empresa',
      identity: 'CNPJ da subconta',
      responsibleName: 'Nome do responsável legal',
      responsibleIdentity: 'CPF do responsável legal',
      responsiblePhone: 'Telefone do responsável legal',
      email: 'Email do responsável legal',
      techName: 'Nome do responsável técnico',
      techIdentity: 'CPF do responsável técnico',
      techEmail: 'Email do responsável técnico',
      techPhone: 'Telefone do responsável técnico',
    },
    formEntityBankData: {
      bank: 'Código do banco',
      accountType: 'Tipo de Conta',
      bankAgency: 'Agência bancária',
      bankAgencyDigit: 'Dígito da Agência bancária',
      bankAccount: 'Conta bancária',
      bankAccountDigit: 'Dígito da Conta bancária',
    },
    radioAcceptanceTerm: {
      term: 'Li e concordo com os termos e condições de uso para o aplicativo Menupass',
    },
    LinkAcceptanceTerm: 'Clique aqui para ler nossos termos.',
  },
  paymentHistory: {
    pageTitle: 'Pagamentos',
  },
  paymentData: {
    pageTitle: 'Pagamentos',
    subtitle: 'Dados Fiscais ',
    content:
      'Insira as informações que serão utilizadas para a emissão \nde nota fiscal referente ao serviço contratado.',
    save: 'Salvar',
    next: 'Próximo',
    cancel: 'Cancelar',
    form: {
      content: '',
      name: 'Razão Social/Nome',
      cnpj: 'CNPJ/CPF',
      cep: 'CEP',
      state: 'Estado',
      city: 'Cidade',
      street: 'Rua',
      number: 'Número',
      complement: 'Complemento',
    },
  },
  paymentCards: {
    pageTitle: 'Pagamentos',
    delete: 'Remover',
    subtitle: 'Cartão',
    content:
      'Caso deseje alterar os dados do cartão de crédito, clique na aba Novo Cartão.',
    noCards: 'Nenhum cartão cadastrado',
    form: {
      content: '',
    },
  },
  paymentAddCard: {
    pageTitle: 'Pagamentos',
    subtitle: 'Novo Cartão',
    content:
      'Insira informações do cartão de crédito que será utilizado para \nefetuar o(s) pagamento(s) do(s) plano(s) contratado(s).',
    save: 'Salvar',
    alreadyHaveCard:
      'Cartão de crédito já cadastrado! Caso queira cadastrar um novo, exclua o atual na tela de cartão',
    cancel: 'Cancelar',
    form: {
      name: 'Nome impresso no cartão',
      number: 'Número do cartão',
      expirationDate: 'Data de expiração',
      securityCode: 'CVV',
      holderNationalId: 'CNPJ/CPF',
      brand: 'Bandeira',
    },
  },
  paymentTabs: {
    payments: 'Histórico',
    paymentsData: 'Dados de Pagamento',
    cards: 'Cartão',
    addCard: 'Novo Cartão',
  },
  planHistory: {
    pageTitle: 'Planos',
  },
  planCreate: {
    pageTitle: 'Planos',
    subtitle: 'Novo Plano',
    content: '',
    save: 'Salvar',
    cancel: 'Cancelar',
    form: {
      content: '',
      name: 'Nome do plano',
      status: 'Status',
      monthlyPrice: 'Preço Mensal',
      annualPrice: 'Preço Anual',
    },
  },
  plantTabs: {
    plans: 'Planos Cadastrados',
    plansAdd: 'Cadastro',
  },
  datatable: {
    paginationQuantity: 'Mostrando',
    paginationQuantityOf: 'de',
    paginationQuantityResults: 'resultados',
    itemsPerPage: 'Itens por página',
  },
  profile: {
    title: 'Perfil',
    subtitle: 'Dados do Perfil',
    content:
      'Atualize as informações de seu perfil se necessário. Caso altere \no número de celular, um novo código deverá ser validado via SMS.',
    save: 'Salvar',
    cancel: 'Cancelar',
    form: {
      content: '',
      name: 'Nome',
      email: 'E-mail',
      password: 'Senha',
      confirmPassword: 'Confirmar Senha',
      phone: 'Celular',
      code: 'Código de Validação (SMS)',
      codeContent:
        'Clique no botão abaixo para receber um código de validação via SMS.',
      sendCode: 'Enviar Código',
    },
  },
  administration: {
    title: 'Administração',
    adminType: {
      admin: 'Administrador',
      manager: 'Gerente',
      owner: 'Dono',
    },
  },
  totem: {
    title: 'Totem',
    adminType: {
      admin: 'Administrador',
      manager: 'Gerente',
      owner: 'Dono',
    },
  },
  administrationCreate: {
    title: 'Administradores',
    new: 'Novo Cadastro',
    content:
      'Crie novos cadastros de usuários do sistema de administração.\nOs perfis incluem: Administrador e Gerente.\n\n Confira as atribuições de cada perfil:\n\nO Administrador possui todos os acessos liberados para incluir,\neditar, excluir dados inseridos na entidade, inclusive todos dados\n financeiros do plano contratado.\n\nO Gerente possui acesso limitado à entidade designada, não\npossuindo acesso aos dados financeiros do plano contratado\ne não podendo criar outros perfis de administração. Todas as\noutras funções, incluindo cadastro de responsáveis, usuários,\ncategorias, produtos, recargas e extratos estarão disponíveis.\n\nTodo usuário possuirá um código de autorização que deve ser\ngerado automaticamente pelo sistema. O administrador deverá\ninformar o código gerado para cada usuário criado. Este código\nserá utilizado para autorizar transações incluindo cancelamentos\ne exceções.',
    content1: '',
    content1Step2: '',
    basicInfo: 'Dados do Administrador',
    cancel: 'Cancelar',
    next: 'Próximo',
    register: 'Salvar',
    back: 'Voltar',
    form: {
      name: 'Nome',
      role: 'Função do Usuário',
      email: 'E-mail',
      phone: 'Celular',
      password: 'Senha',
      confirmPassword: 'Confirmar Senha',
    },
    select: {
      manager: 'Gerente',
      owner: 'Dono',
    },
  },
  totemCreate: {
    title: 'Totem',
    new: 'Novo Cadastro',
    content:
      'Crie novos cadastros de usuários do sistema de totem.\nOs perfis incluem: Cozinha e Totem',
    content1: '',
    content1Step2: '',
    basicInfo: 'Dados do Totem',
    cancel: 'Cancelar',
    next: 'Próximo',
    register: 'Salvar',
    back: 'Voltar',
    form: {
      name: 'Nome',
      role: 'Função',
      email: 'E-mail',
      phone: 'Celular',
      password: 'Senha',
      confirmPassword: 'Confirmar Senha',
    },
    select: {
      kitchen: 'Cozinha',
      totem: 'Totem',
    },
  },
  administrationTabs: {
    profiles: 'Cadastrados',
    create: 'Cadastrar',
  },
  responsible: {
    title: 'Responsáveis',
  },
  responsibleCreate: {
    title: 'Responsáveis',
    new: 'Novo Cadastro',
    content:
      'Como Administrador, você pode cadastrar novos responsáveis\nmanualmente. Também é possível convidar responsáveis para\nse cadastrar por meio do menu Convites.',
    content1: '',
    basicInfo: 'Dados do Responsável',
    othersInfo: 'Dados do Responsável',
    othersContent: '',
    cancel: 'Cancelar',
    next: 'Próximo',
    register: 'Salvar',
    back: 'Voltar',
    form: {
      name: 'Nome',
      role: 'Função',
      email: 'E-mail',
      cpf: 'CPF',
      phone: 'Celular',
      cep: 'CEP',
      state: 'Estado',
      city: 'Cidade',
      street: 'Rua',
      number: 'Número',
      complement: 'Complemento',
      password: 'Senha',
      confirmPassword: 'Confirmar Senha',
    },
    select: {
      responsible: 'Responsável',
      staff: 'Funcionário',
    },
  },
  responsibleTabs: {
    profiles: 'Cadastrados',
    create: 'Cadastrar',
  },
  client: {
    title: 'Usuários',
  },
  clientCreate: {
    title: 'Usuários',
    new: 'Novo Cadastro',
    content:
      'Como Administrador você pode realizar o cadastro de novos\nusuários manualmente. Ao cadastrar um usuário, certifique-se\nde que o responsável tenha sido cadastrado previamente.\n\nCada usuário receberá uma credencial de 4 dígitos gerada\nautomaticamente pelo sistema e você deve criar uma senha\ncom 4 dígitos numéricos para acesso do usuário no Totem.\nPara concluir o cadastro, será obrigatório fazer o upload da \nfoto do usuário.',
    content1: '',
    basicInfo: 'Dados do Usuário',
    cancel: 'Cancelar',
    save: 'Salvar',
    password: 'Senha de Acesso',
    picture: 'Foto',
    next: 'Próximo',
    register: 'Salvar',
    back: 'Voltar',
    form: {
      name: 'Nome',
      search: 'Pesquisar Responsável',
      password: 'Senha',
      confirmPassword: 'Confirmar Senha',
      email: 'Credencial',
      identity: 'Turma',
      nameError: 'Insira ao menos um nome e sobrenome válidos.'
    },
  },
  clientTabs: {
    profiles: 'Cadastrados',
    create: 'Cadastrar',
  },
  product: {
    title: 'Produtos',
  },
  productCreate: {
    title: 'Produtos',
    new: 'Novo Cadastro',
    content:
      'Como Administrador você pode realizar o cadastro de produtos, \nimportar e exportar. Para cadastrar um produto, será necessário\nescolher uma categoria existente ou criar uma nova. Você deve \ninformar o custo do produto, preço, estoque e fazer o upload da\nimagem.',
    content1: '',
    quantity: 'Informações do Produto',
    basicInfo: 'Informações do Produto',
    cancel: 'Cancelar',
    next: 'Próximo',
    register: 'Salvar',
    back: 'Voltar',
    form: {
      name: 'Nome',
      category: 'Categoria',
      cost: 'Custo',
      description: 'Descrição',
      price: 'Preço',
      quantity: 'Estoque',
      isHighlighted: 'Destaque',
      variablePrice: 'Preço variável',
      sector: 'Setor',
      employeeDiscount: 'Desconto para os Funcionários',
      priceForEmployees: 'Preço para Funcionários',

    },
  },
  productTabs: {
    list: 'Cadastrados',
    create: 'Cadastrar',
  },
  category: {
    title: 'Categorias',
  },
  categoryCreate: {
    content1: 'Adicione uma categoria',
    title: 'Categorias',
    new: 'Novo Cadastro',
    content:
      'Como Administrador você pode realizar o cadastro de categorias\nde produtos, importar e exportar. Para cadastrar uma categoria,\nserá necessário informar o nome e fazer o upload da imagem.',
    basicInfo: 'Informações da Categoria',
    basicLogoContent: 'Imagem',
    logoContent: 'Faça o upload de uma imagem de no mínimo 200 x 200 px.',
    cancel: 'Cancelar',
    next: 'Próximo',
    register: 'Salvar',
    back: 'Voltar',
    form: {
      name: 'Nome',
    },
  },
  categoryTabs: {
    list: 'Cadastradas',
    create: 'Cadastrar',
  },
  sector: {
    title: 'Setores',
  },
  sectorCreate: {
    content1: 'Adicione um setor',
    title: 'Setores',
    new: 'Novo Cadastro',
    content: 'Cadastre um novo setor',
    basicInfo: 'Informações do setor',
    namePlaceholder: 'Insira o nome',
  },
  sectorTabs: {
    list: 'Cadastrados',
    create: 'Cadastrar',
  },
  systemTabs: {
    system: 'Sistema',
    periodCreate: 'Cadastrar Período',
    periodList: 'Períodos Cadastrados',
  },
  system: {
    title: 'Sistema',
    subtitle: 'Configurações do Sistema',
    content:
      'As opções abaixo permitem que você configure parâmetros importantes\npara a manutenção do sistema e gestão de recursos.\n\nAjuste o valor mínimo que pode ser carregado por usuários\npara manter a continuidade dos serviços.\n\nEssa configuração ajuda a garantir que os usuários mantenham\num saldo mínimo necessário para operações recorrentes.\n\nEstabeleça um número mínimo para o estoque que, ao ser atingido,\nnotificará automaticamente os gerentes para ações de reposição.\nIsso assegura a gestão eficiente do inventário e evita a escassez de itens\ncríticos.\n\nLembre-se de salvar as alterações realizadas para que as configurações\nentrem em vigor imediatamente.',
    balanceLimit: 'Limite de Saldo',
    balanceLimitContent:
      'Com a Recarga Recorrente ativada, o sistema realiza\nautomaticamente uma nova recarga no mesmo valor da\núltima ao atingir o saldo definido abaixo. Caso não esteja\nativada, ao atingir esse limite, o sistema envia uma\nnotificação de saldo baixo ao responsável cadastrado.\n',
    minimumStock: 'Estoque Mínimo',
    minimumStockContent:
      'Defina a quantidade de estoque mínimo para\nque o sistema emita um alerta e sinalize nos\nrelatórios.',
    form: {
      minimumRecharge: '',
      minimumStock: '',
    },
    orderSchedule: 'Agendamento de Pedidos',
    orderScheduleSub: 'Agendamento de pedidos',
    orderScheduleTime: 'Antecedência Mínima',
    orderScheduleTimeContent: 'Defina o mínimo de horas antecipadas em que os\nresponsáveis poderão agendar um pedido, baseado nos\nperíodos cadastrados.',
    contentSchedule:
      'Ao ativar essa opção, os responsáveis poderão realizar\nagendamentos através do app Menupass.',
    displaySectorsOnThePurchaseTotem: 'Exibir Setores no Totem de Compra',
    displaySectorsOnThePurchaseTotemSub: 'Exibir setores no totem de compra',
    displaySectorsOnTheKitchenTotem: 'Exibir Setores no Totem da Cozinha',
    displaySectorsOnTheKitchenTotemSub: 'Exibir setores no totem da cozinha',
    contentSectors:
      'Ao ativar essa opção, os setores aparecerão no totem de\ncompra.',
    contentSectorsKitchen:
      'Ao ativar essa opção, os setores aparecerão no totem da\ncozinha.',
    responsiblePurchasing: 'Compras de Responsáveis',
    responsiblePurchasingSub: 'Compras de responsáveis',
    responsiblePurchasingContent: 'Ao ativar essa opção, um botão de "comprar" será exibido no\ncard do responsável no app Menupass. O pagamento será\nrealizado diretamente por um meio de pagamento cadastrado ou\nvia pix a cada compra, sem debitar dos créditos dos\ndependentes.',
    discountForEmployeesChildren: 'Desconto para Filhos de Funcionários',
    discountForEmployeesChildrenSub: 'Desconto para filhos de funcionários',
    discountForEmployeesChildrenContent: 'Ao ativar essa opção, os filhos dos funcionários terão o mesmo\ndesconto aplicado aos produtos dos funcionários.',
    employeePurchases: 'Compras de Funcionários',
    employeePurchasesSub: 'Compras de funcionários',
    contentEmployeePurchase:
      'Ao ativar essa opção, um botão de "comprar" será exibido no\ncard do funcionário/responsável no app Menupass. O\npagamento será realizado diretamente por um meio de\npagamento cadastrado ou via pix a cada compra, sem debitar\ndos créditos dos dependentes.',
  },
  systemPeriodCreate: {
    new: 'Novo Cadastro',
    content:
      'cadastre um período e um horário para que seja possível realizar pedidos agendados',
    basicInfo: 'Informações do período',
    hourPlaceholder: 'Insira o Horário',
    time: 'Horário',
    timeSchedule: 'Horas antes do preparo',
    maximumAdvance: 'Antecedência Máxima',
    maximumAdvanceContent: 'Defina o máximo de horas antecipadas em que os responsáveis\npoderão agendar um pedido, baseado nos períodos cadastrados\n(exemplo: 7 dias = 168 horas, ou seja, será possível agendar\npedidos para os próximos 7 dias).',
    period: 'Período',
    describe: 'Descrição',
  },
  financeTabs: {
    order: 'Extratos',
    manualRecharge: 'Recarga Manual',
  },
  finance: {
    title: 'Financeiro',
    search: 'Pesquisar Usuário',
    status: 'Status',
    cardBalance: 'Saldo do cartão',
    cardBalanceDetails: 'Detalhes do cartão',
    cardBalanceDetailsMore: 'Ver mais...',
    textLabel: 'Sem limite definido',
    transaction: 'Transação',
    user: 'Usuário',
    total: 'Total',
    date: 'Data',
    balance: 'Saldo',
    details: 'Detalhes',
    product: 'Produto',
    quantity: 'Quantidade',
    value: 'Valor',
    recurrentRecharge: 'Recorrência',
  },
  manualRechargeCreate: {
    title: 'Financeiro',
    new: 'Nova Recarga',
    content:
      'Como Administrador você pode realizar recargas manuais para/nusuários. Selecione o usuário e informe o valor da recarga a ser/nrealizada. Nesta opção de recargas, você será responsável por/nreceber os valores das recargas diretamente do solicitante.',
    content1: 'Responsáveis: ',
    basicInfo: 'Informações Básicas',
    cancel: 'Cancelar',
    next: 'Próximo',
    save: 'Salvar',
    form: {
      value: 'Valor',
      clients: 'Usuário',
    },
  },
  active: 'Ativo',
  inactive: 'Inativo',
  cardTruncation: '•••• •••• ••••',
  month: 'mês',
  year: 'ano',
  imageContent: 'Faça o upload de uma imagem de no mínimo\n200 x 200 px.',
  edit: 'Editar',
  access: 'Acessar',
  verificationCodeRequest: '[MenuPass] verification code |CODE|',
  verificationCodeRequestReplacer: '|CODE|',
  add_category: 'Adicionar Categoria',
  search: 'Pesquisar',
  authorization_code: 'Código de Autorização',
  generate_new_code: 'Gerar Novo Código',
  personal: 'Física',
  business: 'Jurídica',
  recoveryPassword: 'Recuperar Senha',
  step1: '1/2',
  step2: '2/2',
  sendRecoveryCode:
    'Clique no botão abaixo para receber\num código de validação.',
  sendCode: 'Enviar Código',
  phone: 'Celular',
  phonePlaceholder: '(xx) xxxxx-xxxx',
  recoveryCodePlaceholder: 'Insira o código enviado para seu celular',
  recoveryCode: 'Código',
  forward: 'Avançar',
  password: 'Nova Senha',
  confirmPassword: 'Confirmar Senha',
  save: 'Salvar',
  recoveryPasswordError:
    'Celular não cadastrado, código inválido, ou expirado!',
  confirmation: 'Confirmação',
  confirmationContent:
    'Tem certeza que deseja cancelar a contratação para essa unidade? Você não terá mais acesso aos seus dados e os acessos ao app e totem serão desabilitados.',
  cancelSubscription: 'Cancelar Contratação',
  invitations: 'Convites',
  history: 'Histórico',
  invite: 'Fazer Convite',
  clientRole: 'Cliente',
  adminRole: 'Admin',
  adminClientRole: 'Responsável',
  systemAdminRole: 'Admin',
  staffRole: 'Funcionário',
  new_invitation: 'Novo Convite',
  new_invitation_general: 'Convite Geral',
  new_invitation_content:
    'É possível convidar novos responsáveis ou funcionaríos para o sistema.\nAtráves dos convites.',
  new_invitation_content_general:
    'Cadastre um código para poder convidar novos responsáveis ou funcionaríos\nPara o sistema. Podendo se cadastrar através do código.',
  invitation_info: 'Informações',
  cancel: 'Cancelar',
  send_invite: 'Enviar Convite',
  update_invite: 'Atualizar Convite',
  invitations_subtitle:
    'Insira as informações abaixo para\nfazer um novo convite',
  invitations_general_subtitle:
    'Clique no botão para gerar um novo código geral',
  invitations_general_label_input: 'Código',
  invitations_general_placeholder: 'código',
  name: 'Nome',
  email: 'E-mail',
  type: 'Tipo',
  name_placeholder: 'Insira o nome',
  email_placeholder: 'Insira o e-mail',
  phone_placeholder: '(00) 00000-0000',
  type_placeholder: 'Selecione o tipo de acesso',
  code: 'Código',
  code_placeholder: 'O código será gerado automaticamente',
  staff: 'Funcionário',
  admin_client: 'Responsável',
  invitation_code_request: '[MenuPass] invitation code |CODE|',
  invitation_code_request_replacer: '|CODE|',
  kitchen: 'Cozinha',
  totem_role: 'Totem',
  checking: 'Corrente',
  saving: 'Poupança',
};

export default locale;
